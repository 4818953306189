import http from "../http-common";
import axios from "axios";

const lambdaApproveDenyAxios = axios.create({
  baseURL: "https://knayks2nt3jfwtbrpy434ercou0tpuiq.lambda-url.us-east-1.on.aws",
  headers: {
    "Content-type": "application/json",
    "Cache-Control": "max-age=0"
  }
});

const lambdaDiffsAxios = axios.create({
  baseURL: "https://jxjb4dhyabglu6g5rkmzmm7hfi0mmaof.lambda-url.us-east-1.on.aws",
  headers: {
    "Content-type": "application/json",
    "Cache-Control": "max-age=0"
  }
});

class CompanyDataService {
  getAll(pageSize, page, sortField, sortOrder, queryParams = '') {
    if (page !== 0){
      page = page * pageSize;
    }
    const url = `/parents/${page}/${pageSize}?sortBy=${sortField}&sortOrder=${sortOrder}&${queryParams}`;
    return http.get(url);
  }
  getAllAutocomplete() {
    return http.get(`/parents/autocomplete`);
  }
  getAllSubsidiaries(nid, pageSize, page, sortField, sortOrder, queryParams = '') {
    if (page !== 0){
      page = page * pageSize;
    }
    if (nid !== null) {
      return http.get(`/subsidiaries/${nid}?range=${page}&limit=${pageSize}&sortBy=${sortField}&sortOrder=${sortOrder}&${queryParams}`);
    }
    else {
      return http.get(`/subsidiaries/all?range=${page}&limit=${pageSize}&sortBy=${sortField}&sortOrder=${sortOrder}&${queryParams}`);
    }
  }

  getResearcherActivityReport(pageSize, page, action = '', researcher = '', companyType = '', companyName = '', startDate = '', endDate = '', sortField, sortOrder, download = null, userJson = null) {
    if (page !== 0){
      page = page * pageSize;
    }
    if (download !== null) {
      return http.get(`/researcher-activity-report?range=${page}&limit=${pageSize}&action=${action}&researcher=${researcher}&companyType=${companyType}&companyName=${companyName}&startDate=${startDate}&endDate=${endDate}&sortBy=${sortField}&sortOrder=${sortOrder}&download=true&userJson=${userJson}`);
    }
    else {
      return http.get(`/researcher-activity-report?range=${page}&limit=${pageSize}&action=${action}&researcher=${researcher}&companyType=${companyType}&companyName=${companyName}&startDate=${startDate}&endDate=${endDate}&sortBy=${sortField}&sortOrder=${sortOrder}`);
    }
  }
  getLatestCompanies(pageSize, page, companyType = '', startDate = '', endDate = '', sortField, sortOrder, download = null) {
    if (page !== 0){
      page = page * pageSize;
    }
    if (download !== null) {
      return http.get(`/latest-companies-report?range=${page}&limit=${pageSize}&companyType=${companyType}&startDate=${startDate}&endDate=${endDate}&sortBy=${sortField}&sortOrder=${sortOrder}&download=true`);
    }
    else {
      return http.get(`/latest-companies-report?range=${page}&limit=${pageSize}&companyType=${companyType}&startDate=${startDate}&endDate=${endDate}&sortBy=${sortField}&sortOrder=${sortOrder}`);
    }
  }
  addResearcherActivity(data) {
    return http.post(`/researcher-activity-report`, data);
  }

  getDiffs(NID, page, pageSize) {
    return lambdaDiffsAxios.get(`/get-diffs/${NID}?page=${page}&pageSize=${pageSize}`);
  }

  getVersionHistory(NID) {
    return http.get(`/moderate?nid=${NID}`);
  }
  getCompanySubsidiaries(NID, pageSize, page) {
    if (page !== 0){
      page = page * pageSize;
    }
    return http.get(`/subsidiaries/${NID}/${page}/${pageSize}`);
  }

  getAllDrafts(pageSize, page) {
    if (page !== 0){
      page = page * pageSize;
    }
    return http.get(`/draft-parents/${page}/${pageSize}`);
  }

  getAllPending(pageSize, page) {
    if (page !== 0){
      page = page * pageSize;
    }
    return http.get(`/pending-parents/${page}/${pageSize}`);
  }

  getAllDraftSubsidiaries(NID, pageSize, page) {
    if (page !== 0){
      page = page * pageSize;
    }
    return http.get(`/draft-subsidiaries/${NID}/${page}/${pageSize}`);
  }

  getAllPendingSubsidiaries(NID, pageSize, page) {
    if (page !== 0){
      page = page * pageSize;
    }
    return http.get(`/pending-subsidiaries/${NID}/${page}/${pageSize}`);
  }

  getCountries(searchCompanyName) {
    if (searchCompanyName !== null) {
      return http.get(`/countries?name=${searchCompanyName}`);
    }
    else {
      return http.get(`/countries?name=`);
    }
  }

  getAllCountries(type, parentNID = null, companyKeyword = null) {
    if (type == null) {
      return http.get('/get-all-countries');
    }
    else {
      if (parentNID !== null) {
        return http.get('/get-all-countries?type=' + type + '&parentNID=' + parentNID);
      }
      else {
        return http.get('/get-all-countries?type=' + type + '&companyKeyword=' + companyKeyword);
      }
    }
  }
  getAllCountryPrefixes() {
    return http.get('/get-all-country-prefixes');
  }
  getAllStates(type, parentNID = null, companyKeyword = null) {
    if (type == null) {
      if (parentNID !== null) {
        return http.get('/get-all-states?parentNID=' + parentNID + '&companyKeyword=' + companyKeyword);
      } else {
        return http.get('/get-all-states?companyKeyword=' + companyKeyword);
      }
    }
    else {
      if (parentNID !== null) {
        return http.get('/get-all-states?type=' + type + '&parentNID=' + parentNID + '&companyKeyword=' + companyKeyword);
      } else {
        return http.get('/get-all-states?type=' + type + '&companyKeyword=' + companyKeyword);
      }
    }
  }

  get(id) {
    return http.get(`/company/${id}`);
  }

  getNaics() {
    return http.get(`/naics`);
  }
  
  getDraft(id) {
    return http.get(`/company/draft/${id}`);
  }

  create(data) {
    return http.post("/company", data);
  }

  publish(id, data) {
    return http.patch(`/company/${id}`, data);
  }
  submitForApproval(id, data) {
    return http.put(`/company/approval/${id}`, data);
  }
  update(id, data) {
    return http.put(`/company/${id}`, data);
  }
  approve(id, username) {
    return lambdaApproveDenyAxios.get(`/company/approve/${id}?username=${username}`);
  }
  bulkEdit(id, data) {
    return http.post(`/company/bulk-edit/${id}`, data);
  }
  deny(id, data) {
    return lambdaApproveDenyAxios.post(`/company/deny/${id}`, data);
  }
  delete(id) {
    return http.delete(`/company/${id}`);
  }
  geocode(id) {
    return http.get(`/company/geocode/${id}`);
  }
  findByCompanyName(companyname, pageSize, page) {
    if (page !== 0){
      page = page * pageSize;
    }
    return http.get(`/company-search?name=${companyname}&limit=${pageSize}&range=${page}`);
  }
  
  findDraftsByCompanyName(companyname, pageSize, page) {
    if (page !== 0){
      page = page * pageSize;
    }
    return http.get(`/draft-company-search?name=${companyname}&limit=${pageSize}&range=${page}`);
  }

}

export default new CompanyDataService();
