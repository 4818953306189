import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import CompanyDataService from '../services/CompanyDataService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Autocomplete, TextField, Select, MenuItem, Button, Box, FormControl, InputLabel, InputAdornment, 
    FormHelperText, Accordion, AccordionSummary, AccordionDetails, TextareaAutosize, Typography, Grid,
    Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { getUsers } from '../services/WordpressService'; // Import getUsers
import { AUTH_TOKEN, listOfTitles, countryLabelMap, hidePostalCodeCountries, postalCodeLabelMap } from '../helper';

import { Wrapper, Status } from "@googlemaps/react-wrapper";

const NaicsCodeHelpDialog = ({ open, onClose, naicsCodes, selectedNaics, onAddNaicsCode }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const isCodeSelected = (codeId) => selectedNaics.some(code => code.ID === codeId);

    const filteredCodes = searchTerm
        ? naicsCodes.filter(code =>
            code.ID.toString().includes(searchTerm) || code.Title.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : naicsCodes;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                NAICS Code Help
                <IconButton onClick={onClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    margin="normal"
                    placeholder="Search NAICS Codes or Description"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>NAICS Code</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredCodes.map((code) => (
                            <TableRow key={code.ID}>
                                <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>{code.ID}</span>
                                    {!isCodeSelected(code.ID) && (
                                        <Button
                                            onClick={() => onAddNaicsCode(code)}
                                            style={{ marginLeft: '10px', padding: '0px', minWidth: 'auto' }}
                                            variant="text"
                                        >
                                            (+ add code to list)
                                        </Button>
                                    )}
                                </TableCell>
                                <TableCell>{code.Title}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );
};

const GoogleMapsComponent = ({ center, zoom, onMarkerDragEnd, onZoomChanged }) => {
    const ref = useRef(null);
    const [map, setMap] = useState();
    const [marker, setMarker] = useState();

    useEffect(() => {
        if (ref.current && !map) {
            const newMap = new window.google.maps.Map(ref.current, {
                center,
                zoom,
            });

            const newMarker = new window.google.maps.Marker({
                position: center,
                map: newMap,
                draggable: true
            });

            newMarker.addListener("dragend", onMarkerDragEnd);
            setMap(newMap);
            setMarker(newMarker);
        }
    }, [ref, map, center, zoom, onMarkerDragEnd]);

    useEffect(() => {
        // Re-center the map and reset the marker position when center changes
        if (map && marker) {
            map.setCenter(center);
            marker.setPosition(center);
            map.setZoom(zoom); // Set to a fixed zoom level, or adjust as needed
        }
    }, [center, map, marker, zoom]);

    useEffect(() => {
        if (map) {
            // Add listener for zoom_changed event
            map.addListener("zoom_changed", () => {
                const newZoom = map.getZoom();
                onZoomChanged(newZoom); // Propagate new zoom to parent
            });
        }
    }, [map, onZoomChanged]);

    return <div ref={ref} style={{ height: "300px", width: "100%" }} />;
};

const CompanyEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const isEditMode = id != null; // Check if it's edit mode or add mode
    const [company, setCompany] = useState({});
    const [formData, setFormData] = useState({}); // Form data state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [users, setUsers] = useState([]); // State for users list
    const [isLoading, setIsLoading] = useState(false);
    const companyTypes = ['Private', 'Public']; // save for later: , 'N/A', 'Government', 'Non-Profit', 'Educational', 'Other'];
    const careerListings = [
        { key: 0, value: 'No' },
        { key: 1, value: 'Yes' },
        { key: 2, value: 'N/A' }
    ];
    const [contacts, setContacts] = useState([
        { id: 'contact1', name: formData.Contact1 || '', title: formData.Contact1Title || '' },
        { id: 'contact2', name: formData.Contact2 || '', title: formData.Contact2Title || '' },
        { id: 'contact3', name: formData.Contact3 || '', title: formData.Contact3Title || '' }
    ]);
    const [naicsCodes, setNaicsCodes] = useState([]);
    const [selectedNaics, setSelectedNaics] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [position, setPosition] = useState({ lat: 40.6973709, lng: -74.1444868});
    const [address, setAddress] = useState(""); // Add state to hold the address
    const defaultGeocodeZoom = 15; // A zoom level of 15 is typically good for city-level detail
    const [zoomLevel, setZoomLevel] = useState(10); // Default zoom level
    const [countries, setCountries] = useState([]);
    const [countryPrefixes, setCountryPrefixes] = useState([]); // State to hold the country prefixes
    const [countryStatesMap, setCountryStatesMap] = useState({}); // State to hold the entire mapping
    const [naicsCodesDialogOpen, setNaicsCodesDialogOpen] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    useEffect(() => {
        if (isEditMode) {
            const fetchCompany = async () => {
                try {
                    setLoading(true);
                    const response = await CompanyDataService.get(id);
                    const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
                    const username = user ? user.user_display_name : '';
                    const userRoles = user?.user_roles ?? [];
                        
                    const companyData = {
                        ...response.data.company,
                        Username: username
                    };
        
                    setFormData(companyData);
                    setSelectedCountry(companyData.Country);
        
                    // Update map position if Latitude and Longitude are available
                    if (companyData.Latitude && companyData.Longitude) {
                        setPosition({ lat: parseFloat(companyData.Latitude), lng: parseFloat(companyData.Longitude) });
                    }


                    // Update contacts state with new company data
                    setContacts([
                        { id: 'contact1', name: companyData.Contact1 || '', title: companyData.Contact1Title || '' },
                        { id: 'contact2', name: companyData.Contact2 || '', title: companyData.Contact2Title || '' },
                        { id: 'contact3', name: companyData.Contact3 || '', title: companyData.Contact3Title || '' }
                    ]);
        
                    setLoading(false);
                } catch (err) {
                    setError('Failed to fetch company data');
                    setLoading(false);
                }
            };
            fetchCompany();
        } else {
                // Initialize empty form data for add mode
                setFormData({});
                setLoading(false);
        }
    
        const fetchUsers = async () => {
            try {
                const userList = await getUsers();
                setUsers(userList);
            } catch (error) {
                console.error("Failed to fetch users:", error);
            }
        };
    
        fetchUsers();
    }, [id]);    

    useEffect(() => {
        setContacts([
            { id: 'contact1', name: formData.Contact1 || '', title: formData.Contact1Title || '' },
            { id: 'contact2', name: formData.Contact2 || '', title: formData.Contact2Title || '' },
            { id: 'contact3', name: formData.Contact3 || '', title: formData.Contact3Title || '' }
        ]);
    }, [formData]);
    
    useEffect(() => {
        // Fetch NAICS codes
        const fetchNaics = async () => {
            try {
                const response = await CompanyDataService.getNaics();
                const naicsList = response.data;
                setNaicsCodes(naicsList);
    
                // Initialize selected NAICS from database
                if (formData.NAICS) {
                    const dbNaics = formData.NAICS.split(',').map(code => {
                        const found = naicsList.find(n => n.ID === parseInt(code, 10));
                        return found ? { ID: found.ID, Title: found.Title } : null;
                    }).filter(n => n);
                    setSelectedNaics(dbNaics);
                }
            } catch (error) {
                console.error("Error fetching NAICS codes: ", error);
            }
        };
        fetchNaics();        
    }, [formData.NAICS]);

    const [divisionOptions, setDivisionOptions] = useState([]);

    useEffect(() => {
        const fetchDivisionOptions = async () => {
            try {
                const response = await CompanyDataService.getAllAutocomplete();
                setDivisionOptions(response.data.results);
            } catch (error) {
                console.error("Error fetching division options: ", error);
            }
        };
        fetchDivisionOptions();
    }, []); // Empty dependency array to run only once on mount
    
    useEffect(() => {
        if (formData.DivisionOf && divisionOptions.length) {
            // Ensure formData.DivisionOf is compared as the same type as NID in divisionOptions
            const divisionOfValue = parseInt(formData.DivisionOf, 10); // Convert to number if it's a string
            const division = divisionOptions.find(option => option.NID === divisionOfValue);            
            setSelectedDivision(division || null);
        }
    }, [formData.DivisionOf, divisionOptions]);
    
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await CompanyDataService.getAllCountries(); 
                setCountries(response.data);
            } catch (error) {
                console.error("Failed to fetch countries:", error);
            }
        };
    
        fetchCountries();
    }, []);

    useEffect(() => {
        const fetchCountryPrefixes = async () => {
            try {
                const response = await CompanyDataService.getAllCountryPrefixes(); 
                setCountryPrefixes(response.data);
            } catch (error) {
                console.error("Failed to fetch country prefixes:", error);
            }
        };
    
        fetchCountryPrefixes();
    }, []);

    useEffect(() => {
        const fetchStateProvinces = async () => {
            try {
                const response = await CompanyDataService.getAllStates(); 
                setCountryStatesMap(response.data); // Store the entire mapping
            } catch (error) {
                console.error("Failed to fetch stateProvinces:", error);
            }
        };
    
        fetchStateProvinces();
    }, []);

    useEffect(() => {
        if (!isLoading) {
            scrollToTop();
        }
    }, [isLoading]);

    const stateProvinces = countryStatesMap[selectedCountry] || [];

    const handleNaicsChange = (index, newValue) => {
        const updatedNaics = [...selectedNaics];
        updatedNaics[index] = newValue;
        setSelectedNaics(updatedNaics);
        updateFormDataNaics(updatedNaics);
    };

    const handleAddNaics = () => {
        setSelectedNaics([...selectedNaics, { ID: '', Title: '' }]);
    };

    const handleDeleteNaics = (index) => {
        const updatedNaics = selectedNaics.filter((_, idx) => idx !== index);
        setSelectedNaics(updatedNaics);
        updateFormDataNaics(updatedNaics);
    };

    const updateFormDataNaics = (naicsArray) => {
        setFormData({ ...formData, NAICS: naicsArray.map(n => n.ID).join(',') });
    };    

    const onNaicsDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(selectedNaics);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSelectedNaics(items);
        updateFormDataNaics(items);
    };

    const LoadingOverlay = () => (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
    );

    // Custom filter options function
    const filterNaicsOptions = (options, { inputValue }) => {
        let filtered = options.filter((option) =>
            option.ID.toString().startsWith(inputValue) || option.ID.toString().includes(inputValue)
        );
    
        // Prioritize options that start with the inputValue
        filtered.sort((a, b) => {
            // Convert ID to string for comparison
            let aID = a.ID.toString();
            let bID = b.ID.toString();
    
            // Both start with inputValue, or neither does; fallback to normal sort
            if ((aID.startsWith(inputValue) && bID.startsWith(inputValue)) ||
                (!aID.startsWith(inputValue) && !bID.startsWith(inputValue))) {
                return aID.localeCompare(bID);
            }
            // a starts with inputValue, so it should come first
            if (aID.startsWith(inputValue)) {
                return -1;
            }
            // b starts with inputValue, so it should come first
            return 1;
        });
    
        return filtered;
    };
    

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
            console.debug('user name: ', user.user_display_name);
            // Append the current timestamp and user display name to History in formData
            // Use the following format: 2024-02-08 16:11:10 [From {username}]:
            const timestamp = new Date().toISOString().split('T')[0];
            let newHistoryEntry = `${timestamp} ${user.user_display_name} updated company info`;
            if (!isEditMode) {
                newHistoryEntry = `${timestamp} ${user.user_display_name} created a new company`;
            }

            // Prepend the new history entry to the existing history
            const updatedFormData = {
                ...formData,
                History: `${newHistoryEntry}\n${formData.History || ''}` // Append the new entry before the existing history
            };
            let researcherActivityData = {};
            researcherActivityData.NID = updatedFormData.DraftID ? updatedFormData.DraftID : updatedFormData.NID ? updatedFormData.NID : null;
            researcherActivityData.Title = updatedFormData.Company;
            researcherActivityData.Researcher = user.user_id? user.user_id : null;
            if (!isEditMode) {
                researcherActivityData.Action = 'Created new parent company';
                updatedFormData.Researcher = user.user_id? user.user_id : null;
            }
            else {
                researcherActivityData.Action = 'Saved';
            
            }
            researcherActivityData.Type = updatedFormData.DraftParentID ? 'Sub' : updatedFormData.ParentNID ? 'Sub': 'Parent';
            researcherActivityData.ParentNID = updatedFormData.DraftParentID ? updatedFormData.DraftParentID : updatedFormData.ParentNID ? updatedFormData.ParentNID : null;
            await CompanyDataService.addResearcherActivity(researcherActivityData);
            if (!isEditMode) {
                const createResponse = await CompanyDataService.create(updatedFormData);
                // If createResponse is successful and returns a DraftID, then redirect to /company/:DraftID/edit
                if (createResponse.data[0].DraftID) {
                    const draftInfo = JSON.parse(createResponse.data[0].DraftID);
                    setTimeout(() => {
                        setIsLoading(false);
                        scrollToTop();
                        navigate(`/company/${draftInfo}/edit`);
                    }, 1500);
                }
            } else {
                const updateResponse = await CompanyDataService.update(id, updatedFormData);
                if (updateResponse.data.draftID) {
                    setTimeout(() => {
                        setIsLoading(false);
                        scrollToTop();
                        navigate(`/company/${updateResponse.data.draftID}/edit`);
                    }, 1500);
                } else {
                    // If no draftID is returned, go ot the home page
                    navigate('/');
                }
            }
            // Display success message (use your preferred method)
        } catch (err) {
            setError('Failed to update company');
            setIsLoading(false);
            // Handle error (show error message)
        }
    };
    const handlePublish = async () => {
        try {
            const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
            let researcherActivityData = {};
            researcherActivityData.NID = formData.DraftID ? formData.DraftID : formData.NID;
            researcherActivityData.Title = formData.Company;
            researcherActivityData.Researcher = user.user_id? user.user_id : null;
            researcherActivityData.Action = 'Published';
            researcherActivityData.Type = formData.DraftParentID ? 'Sub' : formData.ParentNID ? 'Sub': 'Parent';
            researcherActivityData.ParentNID = formData.DraftParentID ? formData.DraftParentID : formData.ParentNID ? formData.ParentNID : null;
            await CompanyDataService.addResearcherActivity(researcherActivityData);
            // Append the current timestamp and user display name to History in formData
            // Use the following format: 2024-02-08 16:11:10 [From {username}]:
            const timestamp = new Date().toISOString().split('T')[0];
            let newHistoryEntry = `${timestamp} ${user.user_display_name} directly published the company`;

            // Prepend the new history entry to the existing history
            const updatedFormData = {
                ...formData,
                History: `${newHistoryEntry}\n${formData.History || ''}` // Append the new entry before the existing history
            };

            await CompanyDataService.publish(id, updatedFormData);
            navigate('/companies');
        } catch (error) {
            setError('Failed to publish company');
        }
    };
    
    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this company?")) {
            try {
                const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
                let researcherActivityData = {};
                researcherActivityData.NID = formData.DraftID ? formData.DraftID : formData.NID;
                researcherActivityData.Title = formData.Company;
                researcherActivityData.Researcher = user.user_id? user.user_id : null;
                researcherActivityData.Action = 'Deleted';
                researcherActivityData.Type = formData.DraftParentID ? 'Sub' : formData.ParentNID ? 'Sub': 'Parent';
                researcherActivityData.ParentNID = formData.DraftParentID ? formData.DraftParentID : formData.ParentNID ? formData.ParentNID : null;
                await CompanyDataService.addResearcherActivity(researcherActivityData);
                await CompanyDataService.delete(id);
                navigate('/companies'); // Redirect after deletion
            } catch (error) {
                setError('Failed to delete company');
            }
        }
    };
    
    const handleSubmitForApproval = async () => {
        try {
            const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
            let researcherActivityData = {};
            researcherActivityData.NID = formData.DraftID ? formData.DraftID : formData.NID;
            researcherActivityData.Title = formData.Company;
            researcherActivityData.Researcher = user.user_id? user.user_id : null;
            researcherActivityData.Action = 'Submitted for approval';
            researcherActivityData.Type = formData.DraftParentID ? 'Sub' : formData.ParentNID ? 'Sub': 'Parent';
            researcherActivityData.ParentNID = formData.DraftParentID ? formData.DraftParentID : formData.ParentNID ? formData.ParentNID : null;
            await CompanyDataService.addResearcherActivity(researcherActivityData);
            // Append the current timestamp and user display name to History in formData
            // Use the following format: 2024-02-08 16:11:10 [From {username}]:
            const timestamp = new Date().toISOString().split('T')[0];
            let newHistoryEntry = `${timestamp} ${user.user_display_name} submitted the company for approval`;

            // Prepend the new history entry to the existing history
            const updatedFormData = {
                ...formData,
                History: `${newHistoryEntry}\n${formData.History || ''}` // Append the new entry before the existing history
            };
            await CompanyDataService.submitForApproval(id, updatedFormData);
            navigate('/');
        } catch (error) {
            setError('Failed to submit company for approval.');
        }
    };

    const handleSuggestForDeletion = async () => {
        try {
            const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
            // Set formData['suggestForRemoval'] = 1 and set Status = Suggested for Removal
            // Prepare the updated form data
            // Append the current timestamp and user display name to History in formData
            // Use the following format: 2024-02-08 16:11:10 [From {username}]:
            const timestamp = new Date().toISOString().split('T')[0];
            let newHistoryEntry = `${timestamp} ${user.user_display_name} suggested the entire company for deletion`;

            const updatedFormData = {
                ...formData,
                suggestForRemoval: 1,
                Status: 'Suggested for Removal',
                History: `${newHistoryEntry}\n${formData.History || ''}` // Append the new entry before the existing history
            };

            // Update the state with the new form data
            setFormData(updatedFormData);
            let researcherActivityData = {};
            researcherActivityData.NID = formData.DraftID ? formData.DraftID : formData.NID ? formData.NID : null;
            researcherActivityData.Title = formData.Company;
            researcherActivityData.Researcher = user.user_id? user.user_id : null;
            researcherActivityData.Action = 'Suggested for removal';
            researcherActivityData.Type = formData.DraftParentID ? 'Sub' : formData.ParentNID ? 'Sub': 'Parent';
            researcherActivityData.ParentNID = formData.DraftParentID ? formData.DraftParentID : formData.ParentNID ? formData.ParentNID : null;
            await CompanyDataService.addResearcherActivity(researcherActivityData);
            // Use the updated form data in the service call
            if (!isEditMode) {
                await CompanyDataService.create(updatedFormData);
            } else {
                await CompanyDataService.update(id, updatedFormData);
            }
            navigate('/');
        } catch (error) {
            setError('Failed to suggest company for deletion.');
        }
    };
    const handleUndoSuggestForDeletion = async () => {
        try {
            const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
            // Set formData['suggestForRemoval'] = 1 and set Status = Suggested for Removal
            // Prepare the updated form data
            const timestamp = new Date().toISOString().split('T')[0];
            let newHistoryEntry = `${timestamp} ${user.user_display_name} undid suggest entire company for deletion`;

            const updatedFormData = {
                ...formData,
                suggestForRemoval: 0,
                Status: 'Draft',
                History: `${newHistoryEntry}\n${formData.History || ''}` // Append the new entry before the existing history
            };

            // Update the state with the new form data
            setFormData(updatedFormData);
            let researcherActivityData = {};
            researcherActivityData.NID = formData.DraftID ? formData.DraftID : formData.NID;
            researcherActivityData.Title = formData.Company;
            researcherActivityData.Researcher = user.user_id? user.user_id : null;
            researcherActivityData.Action = 'Suggested to not be removed';
            researcherActivityData.Type = formData.DraftParentID ? 'Sub' : formData.ParentNID ? 'Sub': 'Parent';
            researcherActivityData.ParentNID = formData.DraftParentID ? formData.DraftParentID : formData.ParentNID ? formData.ParentNID : null;
            await CompanyDataService.addResearcherActivity(researcherActivityData);
            // Use the updated form data in the service call
            if (!isEditMode) {
                await CompanyDataService.create(updatedFormData);
            } else {
                await CompanyDataService.update(id, updatedFormData);
            }
            navigate('/');
        } catch (error) {
            setError('Failed to suggest company for deletion.');
        }
    }
    
    // Update form data state
    const handleChange = (event) => {
        const { name, value } = event.target;
        // Update the state based on the input field's name
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    
        // Handle special case for country change
        if (name === 'Country') {
            setSelectedCountry(value);
            // Set the CountryPrefix form data based on the countryPrefix mapping.
            // The mapping is stored in the countryPrefixes state.
            const countryPrefix = countryPrefixes.find(prefix => prefix.Country === value);
            if (countryPrefix) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    CountryPrefix: countryPrefix.CountryPrefix
                }));
            }
            // Reset StateProvince if the country is changed
            if (value !== 'United States' && value !== 'United Kingdom') {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    StateProvince: ''
                }));
            }
        }
    };

    // Conditional rendering based on loading/error states
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;


    // Render each contact
    const renderContact = (contact, index) => (
        <Draggable key={contact.id} draggableId={contact.id} index={index}>
            {(provided) => (
                <Grid container spacing={2} ref={provided.innerRef} {...provided.draggableProps} alignItems="center">
                    <Grid item {...provided.dragHandleProps}> {/* Apply dragHandleProps to the Grid item wrapping the icon */}
                        <DragIndicatorIcon style={{ cursor: 'grab' }} /> {/* Drag Indicator icon */}
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Contact Name"
                            name={`${contact.id}Name`}
                            value={contact.name}
                            onChange={(e) => handleContactChange(e, index)}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id={`${contact.id}Title-label`}>Type</InputLabel>
                            <Select
                                labelId={`${contact.id}Title-label`}
                                id={`${contact.id}Title`}
                                name={`${contact.id}Title`}
                                value={contact.title}
                                label="Type"
                                onChange={(e) => handleContactChange(e, index)}
                            >
                                {listOfTitles.map(option => (
                                    option === '' ? (
                                    <MenuItem key={option} value={option}>
                                        - None -
                                    </MenuItem>
                                    ) : (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                    )
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            )}
        </Draggable>
    );
    
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
    
        const items = Array.from(contacts);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        setContacts(items);
    
        // Update formData based on the new order of contacts
        const updatedFormData = {
            ...formData,
            Contact1: items[0].name,
            Contact1Title: items[0].title,
            Contact2: items[1].name,
            Contact2Title: items[1].title,
            Contact3: items[2].name,
            Contact3Title: items[2].title,
        };
        setFormData(updatedFormData);
    };
    
    
    const handleContactChange = (e, index) => {
        const { name, value } = e.target;
        const updatedContacts = contacts.map((contact, idx) => {
            if (idx === index) {
                const updatedContact = { ...contact };
                if (name.endsWith('Name')) {
                    updatedContact.name = value;
                } else if (name.endsWith('Title')) {
                    updatedContact.title = value;
                }
                return updatedContact;
            }
            return contact;
        });
        setContacts(updatedContacts);
        const updatedFormData = {
            ...formData,
            Contact1: updatedContacts[0].name,
            Contact1Title: updatedContacts[0].title,
            Contact2: updatedContacts[1].name,
            Contact2Title: updatedContacts[1].title,
            Contact3: updatedContacts[2].name,
            Contact3Title: updatedContacts[2].title,
        };
        setFormData(updatedFormData);
    };

    const renderMapStatus = (status) => {
        if (status === Status.LOADING) return <div>Loading...</div>;
        if (status === Status.FAILURE) return <div>Error loading maps</div>;
        return null;
    };
    const onMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
    
        setPosition({ lat: newLat, lng: newLng });
    
        // Update Latitude and Longitude fields
        setFormData(prevFormData => ({
            ...prevFormData,
            Latitude: newLat.toString(),
            Longitude: newLng.toString()
        }));
    };
    
    const geocodeAddress = (event) => {
        event.preventDefault(); // Prevent default form submission

        const geocoder = new window.google.maps.Geocoder();
        const fullAddress = `${formData.Address || ''} ${formData.Address2 || ''} ${formData.City || ''} ${formData.StateProvince || ''} ${formData.PostalCode || ''} ${selectedCountry || ''}`;

        geocoder.geocode({ 'address': fullAddress }, (results, status) => {
            if (status === 'OK') {
                const newLatLng = results[0].geometry.location;
                setPosition({
                    lat: newLatLng.lat(),
                    lng: newLatLng.lng(),
                });

                // Update the form data with new geocoded coordinates
                setFormData(prevFormData => ({
                    ...prevFormData,
                    Latitude: newLatLng.lat().toString(),
                    Longitude: newLatLng.lng().toString()
                }));
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    };
    // Handler to update zoom level state
    const onZoomChanged = (newZoom) => {
        setZoomLevel(newZoom);
    };

    const formatNumberWithCommas = (number) => {
        if (number === '') return '';
        const parts = number.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    };

    const removeCommas = (number) => {
        return number.replace(/,/g, '');
    };

    const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
    const userRoles = user?.user_roles ?? [];
    // Form rendering
    return (
        <Box component="form" onSubmit={handleSubmit}>
            {isLoading && <LoadingOverlay />}
            <h2>{isEditMode ? `Edit Company ${formData.Company}` : 'Add New Company'}</h2>
            <div className="links">
                <div className="link">
                    <Link to={`/company/${id}/review`}>Review Changes</Link>
                </div>
                <div className="link">
                    <Link to={`/company/${formData.NID || id}/subsidiaries`}>View Subsidiaries</Link>
                </div>
            </div>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Company Notes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* Notes Text Area */}
                    <TextareaAutosize
                        aria-label="Company Notes"
                        minRows={3}
                        placeholder="Add any permanent notes here"
                        style={{ width: '100%', marginTop: '1rem' }}
                        name="WorkNotes"
                        value={formData.WorkNotes || ''}
                        onChange={handleChange}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Editorial Scheduled Review and Work Notes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <input type="hidden" name="Username" value={formData.Username || ''} />
                    {/* Researchers Dropdown */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="researcher-label">Researcher</InputLabel>
                        <Select
                            labelId="researcher-label"
                            id="Researcher"
                            name="Researcher"
                            value={users.some(user => user.userId === formData.Researcher) ? formData.Researcher : ''}
                            label="Researcher"
                            onChange={(e) => setFormData({ ...formData, Researcher: e.target.value })}
                        >
                            {users.map(user => (
                                <MenuItem key={user.userId} value={user.userId}>
                                    {user.username}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* Notes Text Area */}
                    <TextareaAutosize
                        aria-label="Working Notes"
                        minRows={3}
                        placeholder="Add any notes here"
                        style={{ width: '100%', marginTop: '1rem' }}
                        name="Notes"
                        value={formData.Notes || ''}
                        onChange={handleChange}
                    />
                    {/* History Display */}
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Previous Working Notes</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" style={{ marginTop: '1rem', whiteSpace: 'pre-line' }}>
                                {formData.History || 'No previous notes'}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Primary Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <TextField
                            fullWidth
                            margin="normal"
                            id="Company"
                            name="Company"
                            label="Company Name"
                            value={formData.Company || ''}
                            onChange={handleChange}
                        />
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Address</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/* Country Dropdown */}
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="country-label">Country</InputLabel>
                                <Select
                                    labelId="country-label"
                                    id="country"
                                    name="Country"
                                    value={formData.Country || ''}
                                    label="Country"
                                    onChange={handleChange}
                                >
                                    {countries.map(country => (
                                        <MenuItem key={country} value={country}>{country}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* Address Fields */}
                            <TextField
                                fullWidth
                                margin="normal"
                                id="address"
                                name="Address"
                                label="Address 1"
                                value={formData.Address || ''}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                id="address2"
                                name="Address2"
                                label="Address 2"
                                value={formData.Address2 || ''}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                id="city"
                                name="City"
                                label="Town/City"
                                value={formData.City || ''}
                                onChange={handleChange}
                            />
                            {/* State Province Field */}
                            {stateProvinces.length > 0 ? (
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="state-province-label">
                                        {countryLabelMap[selectedCountry] || 'State/Province'}
                                    </InputLabel>
                                    <Select
                                        labelId="state-province-label"
                                        id="stateProvince"
                                        name="StateProvince"
                                        value={formData.StateProvince || ''}
                                        label={countryLabelMap[selectedCountry] || 'State/Province'}
                                        onChange={handleChange}
                                    >
                                        {stateProvinces.map(stateProvince => (
                                            <MenuItem key={stateProvince} value={stateProvince}>{stateProvince}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="stateProvince"
                                    name="StateProvince"
                                    label={countryLabelMap[selectedCountry] || 'State/Province'}
                                    value={formData.StateProvince || ''}
                                    onChange={handleChange}
                                />
                            )}
                            {/* Postcode Field */}
                            {!hidePostalCodeCountries.includes(selectedCountry) && (
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="postcode"
                                    name="PostalCode"
                                    label={postalCodeLabelMap[selectedCountry] || 'Postal Code'}
                                    value={formData.PostalCode || ''}
                                    onChange={handleChange}
                                />
                            )}
                                                        <Accordion defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Geolocation</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <button onClick={(event) => geocodeAddress(event)}>Geocode</button>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="Latitude"
                                                name="Latitude"
                                                value={formData.Latitude || ''}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="Longitude"
                                                name="Longitude"
                                                value={formData.Longitude || ''}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Wrapper apiKey={"AIzaSyBzCC2DktRuOoBtvHD3w1p8wIygDHTVyBY"} render={renderMapStatus}>
                                        <GoogleMapsComponent 
                                            center={position} 
                                            zoom={zoomLevel} 
                                            onMarkerDragEnd={onMarkerDragEnd}
                                            onZoomChanged={onZoomChanged}
                                        />
                                    </Wrapper>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        name="Email"
                        value={formData.Email || ''}
                        onChange={handleChange}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Phone"
                                name="Telephone"
                                value={formData.Telephone || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Fax"
                                name="Fax"
                                value={formData.Fax || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Website"
                        name="Website"
                        value={formData.Website || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="LinkedIn"
                        name="LinkedIn"
                        value={formData.LinkedIn || ''}
                        type="url"
                        helperText="Please enter a valid URL, including https://"
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Facebook"
                        name="Facebook"
                        value={formData.Facebook || ''}
                        type="url"
                        helperText="Please enter a valid URL, including https://"
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Instagram"
                        name="Instagram"
                        value={formData.Instagram || ''}
                        type="url"
                        helperText="Please enter a valid URL, including https://"
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="X"
                        name="Twitter"
                        value={formData.Twitter || ''}
                        type="url"
                        helperText="Please enter a valid URL, including https://"
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="YouTube"
                        name="YouTube"
                        value={formData.YouTube || ''}
                        type="url"
                        helperText="Please enter a valid URL, including https://"
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Tiktok"
                        name="Tiktok"
                        value={formData.Tiktok || ''}
                        type="url"
                        helperText="Please enter a valid URL, including https://"
                        onChange={handleChange}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Main Company Contacts</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="contacts">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {contacts.map((contact, index) => renderContact(contact, index))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Meta Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={divisionOptions}
                                getOptionLabel={(option) => option.Company} // Use 'Company' for displaying options
                                renderInput={(params) => <TextField {...params} label="Division Of" />}
                                value={divisionOptions.find(option => option.Company === formData.DivisionOf) || null} // Set value based on 'Company'
                                onChange={(event, newValue) => {
                                    setSelectedDivision(newValue);
                                    setFormData({ ...formData, DivisionOf: newValue ? newValue.Company : '' }); // Store 'Company' in formData
                                }}
                                isOptionEqualToValue={(option, value) => option.Company == value.Company} // Compare based on 'Company'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="# Employees"
                                name="Employee"
                                value={formData.Employee || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="companytype-label">Company Ownership Type</InputLabel>
                                <Select
                                    labelId="companytype-label"
                                    id="CompanyType"
                                    name="CompanyType"
                                    value={formData.CompanyType || ''}
                                    label="Company Ownership Type"
                                    onChange={handleChange}
                                >
                                    {companyTypes.map(option => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Sales"
                                    name="AnnualRevenue"
                                    value={formatNumberWithCommas(formData.AnnualRevenue || '')}
                                    onChange={(e) => {
                                        const rawValue = removeCommas(e.target.value);
                                        if (rawValue === '' || /^\d*\.?\d*$/.test(rawValue)) {
                                            setFormData({ ...formData, AnnualRevenue: rawValue });
                                        }
                                    }}
                                    onBlur={(e) => {
                                        const rawValue = removeCommas(e.target.value);
                                        if (rawValue !== '') {
                                            // Only add decimal places if they were entered by the user
                                            const formattedValue = rawValue.includes('.') ? parseFloat(rawValue).toFixed(2) : rawValue;
                                            setFormData({ ...formData, AnnualRevenue: formattedValue });
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">million</InputAdornment>,
                                    }}
                                    variant="outlined"
                                />
                                <FormHelperText>Enter the known sales for the entire company (including subsidiaries earnings). If not known or not applicable, enter 0.</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                margin="normal"
                                label="Year Company Founded"
                                name="Founded"
                                value={formData.Founded || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <label htmlFor="CompanyDescription" style={{fontWeight:600}}>Company Description:</label>
                            <TextareaAutosize
                                label="Company Description"
                                aria-label="Company Description"
                                minRows={3}
                                placeholder="Enter a company description"
                                style={{ width: '100%', marginTop: '1rem' }}
                                name="CompanyDescription"
                                value={formData.CompanyDescription || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="CareerPosting-label">Career Listing Available</InputLabel>
                                <Select
                                    labelId="CareerPosting-label"
                                    id="CareerPosting"
                                    name="CareerPosting"
                                    value={formData.CareerPosting !== undefined && formData.CareerPosting !== null ? formData.CareerPosting : ''}
                                    label="Career Listing Available"
                                    onChange={handleChange}
                                >
                                    {careerListings.map(option => (
                                        <MenuItem key={option.key} value={option.key}>{option.value}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>Does the company provide a section on their site for career listings?</FormHelperText>
                            </FormControl>
                        </Grid>
                        {/* Conditionally render the Career Page Link field */}
                        {Number(formData.CareerPosting) === 1 && (
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Career Page Link"
                                    name="CareerPageLink"
                                    value={formData.CareerPageLink || ''}
                                    onChange={handleChange}
                                    type="url"
                                    helperText="Please enter a valid URL, including https://"
                                />
                            </Grid>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>NAICS Codes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DragDropContext onDragEnd={onNaicsDragEnd}>
                        <Droppable droppableId="naicsCodes">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {selectedNaics.map((code, index) => (
                                        <Draggable key={code.ID} draggableId={String(code.ID)} index={index}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <Autocomplete
                                                        options={naicsCodes}
                                                        getOptionLabel={(option) => `${option.ID}: ${option.Title}`}
                                                        filterOptions={filterNaicsOptions}
                                                        renderInput={(params) => <TextField {...params} label="NAICS Code" />}
                                                        value={naicsCodes.find(n => n.ID === code.ID) || null} // Correctly find the option based on ID
                                                        onChange={(event, newValue) => handleNaicsChange(index, newValue)}
                                                        isOptionEqualToValue={(option, value) => option.ID === value.ID}
                                                    />

                                                    <Button onClick={() => handleDeleteNaics(index)}>Delete</Button>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Button onClick={handleAddNaics}>Add Another</Button>
                    <Button onClick={() => setNaicsCodesDialogOpen(true)}>NAICS Code Help</Button>
                    <NaicsCodeHelpDialog
                        open={naicsCodesDialogOpen}
                        onClose={() => setNaicsCodesDialogOpen(false)}
                        naicsCodes={naicsCodes}
                        selectedNaics={selectedNaics}
                        onAddNaicsCode={(code) => {
                            if (!selectedNaics.find(n => n.ID === code.ID)) {
                                setSelectedNaics([...selectedNaics, code]);
                                // Update formData for NAICS codes
                                updateFormDataNaics([...selectedNaics, code]);
                            }
                        }}
                    />

                </AccordionDetails>
            </Accordion>
            <Button type="submit" color="primary">Save</Button>
            {userRoles.includes("administrator") && isEditMode && (
                <Button type="button" color="secondary" onClick={handlePublish}>Publish</Button>
            )}
            {userRoles.includes("administrator") && isEditMode && (
                <Button type="button" color="error" onClick={handleDelete}>Delete</Button>
            )}
            {isEditMode && (
                <Button type="button" color="primary" onClick={handleSubmitForApproval}>Submit Entire Company for Approval</Button>
            )}
            {formData.suggestForRemoval === 1 && isEditMode && <Button type="button" color="secondary" onClick={handleUndoSuggestForDeletion}>Undo Suggest for Removal</Button>}
            {formData.suggestForRemoval !== 1 && isEditMode && <Button type="button" color="error" onClick={handleSuggestForDeletion}>Suggest Entire Company for Deletion</Button>}
        </Box>
    );
};

export default CompanyEdit;