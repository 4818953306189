import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CompanyDataService from '../services/CompanyDataService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Autocomplete, TextField, Select, MenuItem, Button, Box, FormControl, InputLabel, InputAdornment, FormHelperText, Accordion, AccordionSummary, AccordionDetails, TextareaAutosize, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { getUsers } from '../services/WordpressService'; // Import getUsers
import { AUTH_TOKEN, listOfTitles, countryLabelMap, hidePostalCodeCountries, postalCodeLabelMap } from '../helper';
import { Wrapper, Status } from "@googlemaps/react-wrapper";

const GoogleMapsComponent = ({ center, zoom, onMarkerDragEnd, onZoomChanged }) => {
    const ref = useRef(null);
    const [map, setMap] = useState();
    const [marker, setMarker] = useState();

    useEffect(() => {
        if (ref.current && !map) {
            const newMap = new window.google.maps.Map(ref.current, {
                center,
                zoom,
            });

            const newMarker = new window.google.maps.Marker({
                position: center,
                map: newMap,
                draggable: true
            });

            newMarker.addListener("dragend", onMarkerDragEnd);
            setMap(newMap);
            setMarker(newMarker);
        }
    }, [ref, map, center, zoom, onMarkerDragEnd]);

    useEffect(() => {
        // Re-center the map and reset the marker position when center changes
        if (map && marker) {
            map.setCenter(center);
            marker.setPosition(center);
            map.setZoom(zoom); // Set to a fixed zoom level, or adjust as needed
        }
    }, [center, map, marker, zoom]);

    useEffect(() => {
        if (map) {
            // Add listener for zoom_changed event
            map.addListener("zoom_changed", () => {
                const newZoom = map.getZoom();
                onZoomChanged(newZoom); // Propagate new zoom to parent
            });
        }
    }, [map, onZoomChanged]);

    return <div ref={ref} style={{ height: "300px", width: "100%" }} />;
};


const SubsidiaryEdit = ({ companyId, parentId, onClose }) => {
    const id = companyId;
    let parentType = 'published';
    if (parentId > 10000000) {
        parentType = 'draft';
    }
    console.debug("Parent id: " + parentId);
    const navigate = useNavigate();
    const isEditMode = id != null; // Check if it's edit mode or add mode
    const [company, setCompany] = useState({});
    const [formData, setFormData] = useState({}); // Form data state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [users, setUsers] = useState([]); // State for users list
    const [contacts, setContacts] = useState([
        { id: 'contact1', name: formData.Contact1 || '', title: formData.Contact1Title || '' },
        { id: 'contact2', name: formData.Contact2 || '', title: formData.Contact2Title || '' }
    ]);
    const [selectedParent, setSelectedParent] = useState(null);
    const [parentOptions, setParentOptions] = useState([]);
    const [position, setPosition] = useState({ lat: 40.6973709, lng: -74.1444868});
    const [address, setAddress] = useState(""); // Add state to hold the address
    const defaultGeocodeZoom = 15; // A zoom level of 15 is typically good for city-level detail
    const [zoomLevel, setZoomLevel] = useState(10); // Default zoom level
    const [countries, setCountries] = useState([]);
    const [countryPrefixes, setCountryPrefixes] = useState([]);
    const [countryStatesMap, setCountryStatesMap] = useState({}); // State to hold the entire mapping

    const subsidiaryTypes = [
        'Affiliate Member', 'Branch Office', 'Consumer Relations', 'Country Headquarters',
        'Distribution Center', 'Head Office', 'Headquarters', 'Joint Venture',
        'Manufacturing Facility', 'Production Facility', 'R & D Center', 'Regional Headquarters',
        'Representative Office', 'Retail Location', 'Sales Office', 'Service Centers'];

    useEffect(() => {
        if (isEditMode) {
            const fetchCompany = async () => {
                try {
                    setLoading(true);
                    const response = await CompanyDataService.get(id);
                    const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
                    const username = user ? user.user_display_name : '';
                    const userRoles = user?.user_roles ?? [];
                
                    const companyData = {
                        ...response.data.company,
                        Username: username
                    };
        
                    setFormData(companyData);
                    setSelectedCountry(companyData.Country);
        
                    // Update map position if Latitude and Longitude are available
                    if (companyData.Latitude && companyData.Longitude) {
                        setPosition({ lat: parseFloat(companyData.Latitude), lng: parseFloat(companyData.Longitude) });
                    }

                    // Update contacts state with new company data
                    setContacts([
                        { id: 'contact1', name: companyData.Contact1 || '', title: companyData.Contact1Title || '' },
                        { id: 'contact2', name: companyData.Contact2 || '', title: companyData.Contact2Title || '' },
                    ]);
        
                } catch (err) {
                    setError('Failed to fetch company data');
                    setLoading(false);
                }
            };
            fetchCompany();
        } else {
                // Initialize empty form data for add mode
                setLoading(true);
                if (parentType === 'draft') {
                    setFormData({
                        DraftParentID: parentId,
                    });
                } else {
                    setFormData({
                        ParentNID: parentId,
                    });
                }
        }
    
        const fetchUsers = async () => {
            try {
                const userList = await getUsers();
                setUsers(userList);
            } catch (error) {
                console.error("Failed to fetch users:", error);
            }
        };
    
        fetchUsers();
    }, [id]);    

    useEffect(() => {
        setContacts([
            { id: 'contact1', name: formData.Contact1 || '', title: formData.Contact1Title || '' },
            { id: 'contact2', name: formData.Contact2 || '', title: formData.Contact2Title || '' }
        ]);
    }, [formData]);
    
    useEffect(() => {
        const fetchParentOptions = async () => {
            try {
                const response = await CompanyDataService.getAllAutocomplete();
                setParentOptions(response.data.results);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching parent options: ", error);
                setLoading(false);
            }
        };
        fetchParentOptions();
    }, []); // Empty dependency array to run only once on mount
    
    useEffect(() => {
        if (parentOptions.length) {
            // Determine which ID to use for the parent
            const parentID = formData.DraftParentID || formData.ParentNID;
    
            if (parentID) {
                const parentValue = parseInt(parentID, 10);
                const parent = parentOptions.find(option => option.NID === parentValue);
                setSelectedParent(parent || null);
            } else {
                setSelectedParent(null);
            }
        }
    }, [formData, parentOptions]);
    

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await CompanyDataService.getAllCountries(); 
                setCountries(response.data);
            } catch (error) {
                console.error("Failed to fetch countries:", error);
            }
        };
    
        fetchCountries();
    }, []);

    useEffect(() => {
        const fetchCountryPrefixes = async () => {
            try {
                const response = await CompanyDataService.getAllCountryPrefixes(); 
                setCountryPrefixes(response.data);
            } catch (error) {
                console.error("Failed to fetch country prefixes:", error);
            }
        };
    
        fetchCountryPrefixes();
    }, []);

    useEffect(() => {
        const fetchStateProvinces = async () => {
            try {
                const response = await CompanyDataService.getAllStates(); 
                setCountryStatesMap(response.data); // Store the entire mapping
            } catch (error) {
                console.error("Failed to fetch stateProvinces:", error);
            }
        };
    
        fetchStateProvinces();
    }, []);

    const stateProvinces = countryStatesMap[selectedCountry] || [];
    const submitData = async (dataToSubmit) => {
        let submissionSuccessful = false;
        let updatedFormData = { ...formData, ...dataToSubmit };

        // Check if DraftParentID is set and greater than the threshold
        if (dataToSubmit.DraftParentID && dataToSubmit.DraftParentID > 10000000) {
            dataToSubmit.ParentNID = null; // Clear ParentNID if DraftParentID is valid
        } 
        // Check if ParentNID is set and greater than the threshold
        else if (dataToSubmit.ParentNID && dataToSubmit.ParentNID > 10000000) {
            dataToSubmit.DraftParentID = dataToSubmit.ParentNID; // Move ParentNID to DraftParentID
            dataToSubmit.ParentNID = null; // Clear ParentNID
        }
        // If neither ID is greater than the threshold, use DraftParentID as ParentNID (if set) and clear DraftParentID
        else {
            dataToSubmit.ParentNID = dataToSubmit.DraftParentID || dataToSubmit.ParentNID;
            dataToSubmit.DraftParentID = null;
        }
        try {
            if (!isEditMode) {
                const response = await CompanyDataService.create(updatedFormData);
                submissionSuccessful = response.status === 200; // Or your logic to determine success\
            } else {
                const response = await CompanyDataService.update(id, updatedFormData);
                submissionSuccessful = response.status === 200; // Or your logic to determine success
            }
            if (submissionSuccessful) {
                onClose(); // Close the modal on successful submission
                // Optionally navigate or trigger a refresh
            } else {
                // Handle the case where submission is not successful
                setError('Submission was not successful');
            }
        } catch (err) {
            setError('Failed to update company');
            // Handle error (show error message)
        }
    };
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
        // Set formData['suggestForRemoval'] = 1 and set Status = Suggested for Removal
        // Prepare the updated form data
        const timestamp = new Date().toISOString().split('T')[0];
        let newHistoryEntry = `${timestamp} ${user.user_display_name} saved subsidiary`;
        if (!isEditMode) {
            newHistoryEntry = `${timestamp} ${user.user_display_name} created a new subsidiary`;
        }

        const updatedFormData = {
            ...formData,
            History: `${newHistoryEntry}\n${formData.History || ''}` // Append the new entry before the existing history
        };
        let researcherActivityData = {};
        researcherActivityData.NID = updatedFormData.DraftID ? updatedFormData.DraftID : updatedFormData.NID ? updatedFormData.NID : null;
        researcherActivityData.Title = updatedFormData.Company;
        researcherActivityData.Researcher = user.user_id? user.user_id : null;
        if (!isEditMode) {
            researcherActivityData.Action = 'Created new subsidiary company';
        }
        else {
            researcherActivityData.Action = 'Saved';
        }
        researcherActivityData.Type = updatedFormData.DraftParentID ? 'Sub' : updatedFormData.ParentNID ? 'Sub': 'Parent';
        researcherActivityData.ParentNID = updatedFormData.DraftParentID ? updatedFormData.DraftParentID : updatedFormData.ParentNID ? updatedFormData.ParentNID : null;
        await CompanyDataService.addResearcherActivity(researcherActivityData);
        await submitData(updatedFormData);
    };
    

    const handleSpecialSubmit = async (event, actionType) => {
        event.preventDefault();
        try {
            const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
            // Set formData['suggestForRemoval'] = 1 and set Status = Suggested for Removal
            // Prepare the updated form data
            const timestamp = new Date().toISOString().split('T')[0];
            let newHistoryEntry = `${timestamp} ${user.user_display_name} suggested subsidiary for removal`;
            if (actionType === 'done') {
                newHistoryEntry = `${timestamp} ${user.user_display_name} marked subsidiary as done`;
            } else if (actionType === 'undoRemove') {
                newHistoryEntry = `${timestamp} ${user.user_display_name} suggested to not remove subsidiary`;
            } else if (actionType === 'undoDone') {
                newHistoryEntry = `${timestamp} ${user.user_display_name} marked subsidiary as not done`;
            }

            let updatedFormData = {
                ...formData,
                History: `${newHistoryEntry}\n${formData.History || ''}` // Append the new entry before the existing history
            };
            let researcherActivityData = {};
            researcherActivityData.NID = formData.DraftID ? formData.DraftID : formData.NID ? formData.NID : null;
            researcherActivityData.Title = formData.Company;
            researcherActivityData.Researcher = user.user_id? user.user_id : null;
            researcherActivityData.Type = formData.DraftParentID ? 'Sub' : formData.ParentNID ? 'Sub': 'Parent';
            researcherActivityData.ParentNID = formData.DraftParentID ? formData.DraftParentID : formData.ParentNID ? formData.ParentNID : null;
            if (actionType === 'remove') {
                updatedFormData.suggestForRemoval = 1;
                researcherActivityData.Action = 'Suggested for removal';
                updatedFormData.Status = 'Suggested for Removal'
            } else if (actionType === 'done') {
                updatedFormData.markedDone = 1;
                researcherActivityData.Action = 'Marked as done';
            } else if (actionType === 'undoRemove') {
                updatedFormData.suggestForRemoval = 0;
                researcherActivityData.Action = 'Suggested to not be removed';
                updatedFormData.Status = 'Draft'
            } else if (actionType === 'undoDone') {
                updatedFormData.markedDone = 0;
                researcherActivityData.Action = 'Marked as not done';
            }
            await CompanyDataService.addResearcherActivity(researcherActivityData);
            // Update the formData state with the new values
            setFormData(updatedFormData);
        
            // Submit the updated form data
            await submitData(updatedFormData);
        } catch (error) {
            setError('Failed to suggest company for deletion.');
        }

    };
    
    
    const handlePublish = async () => {
        let submissionSuccessful = false;

        try {
            const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
            // Set formData['suggestForRemoval'] = 1 and set Status = Suggested for Removal
            // Prepare the updated form data
            const timestamp = new Date().toISOString().split('T')[0];
            let newHistoryEntry = `${timestamp} ${user.user_display_name} directly published a subsidiary`;

            const updatedFormData = {
                ...formData,
                History: `${newHistoryEntry}\n${formData.History || ''}` // Append the new entry before the existing history
            };
            let researcherActivityData = {};
            researcherActivityData.NID = updatedFormData.DraftID ? updatedFormData.DraftID : updatedFormData.NID;
            researcherActivityData.Title = updatedFormData.Company;
            researcherActivityData.Researcher = user.user_id? user.user_id : null;
            researcherActivityData.Action = 'Published';
            researcherActivityData.Type = updatedFormData.DraftParentID ? 'Sub' : updatedFormData.ParentNID ? 'Sub': 'Parent';
            researcherActivityData.ParentNID = updatedFormData.DraftParentID ? updatedFormData.DraftParentID : updatedFormData.ParentNID ? updatedFormData.ParentNID : null;
            await CompanyDataService.addResearcherActivity(researcherActivityData);
            const response = await CompanyDataService.publish(id, updatedFormData);
            submissionSuccessful = response.status === 200; // Or your logic to determine success\
            if (submissionSuccessful) {
                onClose();
            } else {
                setError('Failed to publish company');
            }
        } catch (error) {
            setError('Failed to publish company');
        }
    };
    
    const handleDelete = async () => {
        let submissionSuccessful = false;

        try {
            const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
            let researcherActivityData = {};
            researcherActivityData.NID = formData.DraftID ? formData.DraftID : formData.NID;
            researcherActivityData.Title = formData.Company;
            researcherActivityData.Researcher = user.user_id? user.user_id : null;
            researcherActivityData.Action = 'Deleted';
            researcherActivityData.Type = formData.DraftParentID ? 'Sub' : formData.ParentNID ? 'Sub': 'Parent';
            researcherActivityData.ParentNID = formData.DraftParentID ? formData.DraftParentID : formData.ParentNID ? formData.ParentNID : null;
            await CompanyDataService.addResearcherActivity(researcherActivityData);
            const response = await CompanyDataService.delete(id);
            submissionSuccessful = response.status === 200; // Or your logic to determine success\
            if (submissionSuccessful) {
                onClose();
            }
            else {
                setError('Failed to delete company');
            }
        } catch (error) {
            setError('Failed to delete company');
        }
    };
    
    // Update form data state
    const handleChange = (event) => {
        const { name, value } = event.target;
        // Update the state based on the input field's name
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    
        // Handle special case for country change
        if (name === 'Country') {
            setSelectedCountry(value);
            // Set the CountryPrefix form data based on the countryPrefix mapping.
            // The mapping is stored in the countryPrefixes state.
            const countryPrefix = countryPrefixes.find(prefix => prefix.Country === value);
            if (countryPrefix) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    CountryPrefix: countryPrefix.CountryPrefix
                }));
            }
            // Reset StateProvince if the country is changed
            if (value !== 'United States' && value !== 'United Kingdom') {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    StateProvince: ''
                }));
            }
        }
    };
    // Determine label for State/Province field
    const stateProvinceLabel = selectedCountry === 'United Kingdom' ? 'County' 
                             : selectedCountry === 'United States' ? 'State' 
                             : '';


    // Options for State/Province dropdown based on selected country
    const stateProvinceOptions = selectedCountry === 'United Kingdom' 
        ? ['England', 'Wales', 'Scotland', 'Northern Ireland'] 
        : selectedCountry === 'United States' 
        ? ['Arkansas', 'Illinois', 'New York', 'Texas', 'Pennsylvania', 'Colorado', 'Virginia'] 
        : [];

    // Conditional rendering based on loading/error states
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;


    // Render each contact
    const renderContact = (contact, index) => (
        <Draggable key={contact.id} draggableId={contact.id} index={index}>
            {(provided) => (
                <Grid container spacing={2} ref={provided.innerRef} {...provided.draggableProps} alignItems="center">
                    <Grid item {...provided.dragHandleProps}> {/* Apply dragHandleProps to the Grid item wrapping the icon */}
                        <DragIndicatorIcon style={{ cursor: 'grab' }} /> {/* Drag Indicator icon */}
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Contact Name"
                            name={`${contact.id}Name`}
                            value={contact.name}
                            onChange={(e) => handleContactChange(e, index)}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id={`${contact.id}Title-label`}>Type</InputLabel>
                            <Select
                                labelId={`${contact.id}Title-label`}
                                id={`${contact.id}Title`}
                                name={`${contact.id}Title`}
                                value={contact.title}
                                label="Type"
                                onChange={(e) => handleContactChange(e, index)}
                            >
                                {listOfTitles.map(option => (
                                    option === '' ? (
                                    <MenuItem key={option} value={option}>
                                        - None -
                                    </MenuItem>
                                    ) : (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                    )
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            )}
        </Draggable>
    );
    
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
    
        const items = Array.from(contacts);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        setContacts(items);
    
        // Update formData based on the new order of contacts
        const updatedFormData = {
            ...formData,
            Contact1: items[0].name,
            Contact1Title: items[0].title,
            Contact2: items[1].name,
            Contact2Title: items[1].title,
        };
        setFormData(updatedFormData);
    };
    
    
    const handleContactChange = (e, index) => {
        const { name, value } = e.target;
        const updatedContacts = contacts.map((contact, idx) => {
            if (idx === index) {
                const updatedContact = { ...contact };
                if (name.endsWith('Name')) {
                    updatedContact.name = value;
                } else if (name.endsWith('Title')) {
                    updatedContact.title = value;
                }
                return updatedContact;
            }
            return contact;
        });
        setContacts(updatedContacts);
        const updatedFormData = {
            ...formData,
            Contact1: updatedContacts[0].name,
            Contact1Title: updatedContacts[0].title,
            Contact2: updatedContacts[1].name,
            Contact2Title: updatedContacts[1].title,
        };
        setFormData(updatedFormData);
    };

    const renderMapStatus = (status) => {
        if (status === Status.LOADING) return <div>Loading...</div>;
        if (status === Status.FAILURE) return <div>Error loading maps</div>;
        return null;
    };
    const onMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
    
        setPosition({ lat: newLat, lng: newLng });
    
        // Update Latitude and Longitude fields
        setFormData(prevFormData => ({
            ...prevFormData,
            Latitude: newLat.toString(),
            Longitude: newLng.toString()
        }));
    };
    
    const geocodeAddress = (event) => {
        event.preventDefault(); // Prevent default form submission

        const geocoder = new window.google.maps.Geocoder();
        const fullAddress = `${formData.Address || ''} ${formData.Address2 || ''} ${formData.City || ''} ${formData.StateProvince || ''} ${formData.PostalCode || ''} ${selectedCountry || ''}`;

        geocoder.geocode({ 'address': fullAddress }, (results, status) => {
            if (status === 'OK') {
                const newLatLng = results[0].geometry.location;
                setPosition({
                    lat: newLatLng.lat(),
                    lng: newLatLng.lng(),
                });

                // Update the form data with new geocoded coordinates
                setFormData(prevFormData => ({
                    ...prevFormData,
                    Latitude: newLatLng.lat().toString(),
                    Longitude: newLatLng.lng().toString()
                }));
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    };
    // Handler to update zoom level state
    const onZoomChanged = (newZoom) => {
        setZoomLevel(newZoom);
    };
    const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
    const userRoles = user?.user_roles ?? [];

    // Form rendering
    return (
        <Box component="form" onSubmit={handleSubmit}>
            <h2>{isEditMode ? `Edit Subsidiary ${formData.Company}` : 'Add New Subsidiary'}</h2>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Primary Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Autocomplete
                        options={parentOptions}
                        getOptionLabel={(option) => `${option.NID}: ${option.Company}`}
                        renderInput={(params) => <TextField {...params} label="Subsidiary Of" />}
                        value={selectedParent}
                        onChange={(event, newValue) => {
                            if (newValue == null) {
                                setSelectedParent(-1);
                                setFormData({ ...formData, DraftParentID: null, ParentNID: -1 });
                            } else {
                                setSelectedParent(newValue);
                                // Update formData based on the parent type
                                if (parentType === 'draft') {
                                    setFormData({ ...formData, DraftParentID: newValue ? newValue.NID : '' });
                                } else {
                                    setFormData({ ...formData, ParentNID: newValue ? newValue.NID : '', DraftParentID: '' });
                                }
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option.NID === value.NID}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        id="Company"
                        name="Company"
                        label="Subsidiary Name"
                        value={formData.Company || ''}
                        onChange={handleChange}
                    />
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Address</Typography>
                        </AccordionSummary>
                        <AccordionDetails>                            
                            {/* Country Dropdown */}
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="country-label">Country</InputLabel>
                                <Select
                                    labelId="country-label"
                                    id="country"
                                    name="Country"
                                    value={formData.Country || ''}
                                    label="Country"
                                    onChange={handleChange}
                                >
                                    {countries.map(country => (
                                        <MenuItem key={country} value={country}>{country}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* Address Fields */}
                            <TextField
                                fullWidth
                                margin="normal"
                                id="address"
                                name="Address"
                                label="Address 1"
                                value={formData.Address || ''}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                id="address2"
                                name="Address2"
                                label="Address 2"
                                value={formData.Address2 || ''}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                id="city"
                                name="City"
                                label="Town/City"
                                value={formData.City || ''}
                                onChange={handleChange}
                            />
                            {/* State Province Field */}
                            {stateProvinces.length > 0 ? (
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="state-province-label">
                                        {countryLabelMap[selectedCountry] || 'State/Province'}
                                    </InputLabel>
                                    <Select
                                        labelId="state-province-label"
                                        id="stateProvince"
                                        name="StateProvince"
                                        value={formData.StateProvince || ''}
                                        label={countryLabelMap[selectedCountry] || 'State/Province'}
                                        onChange={handleChange}
                                    >
                                        {stateProvinces.map(stateProvince => (
                                            <MenuItem key={stateProvince} value={stateProvince}>{stateProvince}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="stateProvince"
                                    name="StateProvince"
                                    label={countryLabelMap[selectedCountry] || 'State/Province'}
                                    value={formData.StateProvince || ''}
                                    onChange={handleChange}
                                />
                            )}
                            {/* Postcode Field */}
                            {!hidePostalCodeCountries.includes(selectedCountry) && (
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="postcode"
                                    name="PostalCode"
                                    label={postalCodeLabelMap[selectedCountry] || 'Postal Code'}
                                    value={formData.PostalCode || ''}
                                    onChange={handleChange}
                                />
                            )}
                            <Accordion defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Geolocation</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <button onClick={(event) => geocodeAddress(event)}>Geocode</button>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="Latitude"
                                                name="Latitude"
                                                value={formData.Latitude || ''}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="Longitude"
                                                name="Longitude"
                                                value={formData.Longitude || ''}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Wrapper apiKey={"AIzaSyBzCC2DktRuOoBtvHD3w1p8wIygDHTVyBY"} render={renderMapStatus}>
                                        <GoogleMapsComponent 
                                            center={position} 
                                            zoom={zoomLevel} 
                                            onMarkerDragEnd={onMarkerDragEnd}
                                            onZoomChanged={onZoomChanged}
                                        />
                                    </Wrapper>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        name="Email"
                        value={formData.Email || ''}
                        onChange={handleChange}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Phone"
                                name="Telephone"
                                value={formData.Telephone || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Fax"
                                name="Fax"
                                value={formData.Fax || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Website"
                        name="Website"
                        value={formData.Website || ''}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="subsidiarytype-label">Subsidiary Type</InputLabel>
                        <Select
                            labelId="subsidiarytype-label"
                            id="SubsidiaryType"
                            name="SubsidiaryType"
                            value={formData.SubsidiaryType || ''}
                            label="Subsidiary Type"
                            onChange={handleChange}
                        >
                            {subsidiaryTypes.map(option => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Main Company Contacts</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="contacts">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {contacts.map((contact, index) => renderContact(contact, index))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </AccordionDetails>
            </Accordion>
            <Button type="submit" color="primary">Save</Button>
            {parentType !== 'draft' && formData.NID && userRoles.includes("administrator") && (
                <Button type="button" color="secondary" onClick={handlePublish}>Publish</Button>
            )}
            {isEditMode && userRoles.includes("administrator") && (
                <Button type="button" color="error" onClick={handleDelete}>Delete</Button>
            )}
            {/* if suggestForRemoval is 1, show button for 'Undo Suggest for Removal' */}
            {isEditMode && formData.suggestForRemoval === 1 && <Button onClick={(e) => handleSpecialSubmit(e, 'undoRemove')}>Undo Suggest for Removal</Button>}
            {/* if markedDone is 1, show button for 'Undo Mark as Done' */}
            {isEditMode && formData.markedDone === 1 && <Button onClick={(e) => handleSpecialSubmit(e, 'undoDone')}>Undo Mark as Done</Button>}
            {/* if markedDone is 0 or not set, show button for 'mark as done' */}
            {isEditMode && formData.markedDone !== 1 && <Button onClick={(e) => handleSpecialSubmit(e, 'done')}>Mark as Done</Button>}
            {/* if suggestForRemoval is 0 or not set, show button for 'suggest for removal' */}
            {isEditMode && formData.suggestForRemoval !== 1 && <Button onClick={(e) => handleSpecialSubmit(e, 'remove')}>Suggest for Removal</Button>}
        </Box>
    );
};

export default SubsidiaryEdit;