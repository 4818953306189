import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link, Routes, NavLink } from 'react-router-dom';

import NotFound from './components/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import { AUTH_TOKEN } from './helper';
import CompaniesList from './components/CompaniesList';
import SubsidiariesList from './components/SubsidiariesList';
import CompanyEdit from './components/CompanyEdit';
import SubsidiaryEdit from './components/SubsidiaryEdit';
import CompaniesPendingReview from './components/CompaniesPendingReview';
import LoginForm from './components/LoginForm';
import AssignResearcher from './components/AssignResearcher';
import ReviewChanges from './components/ReviewChanges';
import ResearcherActivityReport from './components/ResearcherActivityReport';
import ModerateCompany from './components/ModerateCompany';
import LatestCompanies from './components/LatestCompanies';

class App extends Component {
  // Method to handle logout
  handleLogout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    // Optionally, you can redirect the user to the login page or refresh the page
    window.location.href = '/login';
  };
  render() {
    const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
    const userRoles = user?.user_roles ?? [];
    return (
      <Router>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/companies"} className="navbar-brand">
            {
              user && (user.user_roles.includes("administrator") || user.user_roles.includes("researcher")) ? (
                <div className="navbar-user">
                  Uniworld Online -- Logged in as: {user.user_display_name}
                </div>
              ) : (
                <div className="navbar-user">
                  Log in
                </div>
              )
            }
          </Link>
          <div className="navbar-nav mr-auto">
            {userRoles.includes("researcher") || userRoles.includes("administrator") ? (
              <li className="nav-item">
                <NavLink to="/" className={({ isActive }) =>
                    isActive
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  My Assigned Companies
                </NavLink>
              </li>
            ) : null}
            {userRoles.includes("administrator") ? (
              <li className="nav-item">
                <NavLink
                  to="/companies"
                  className={({ isActive }) =>
                    isActive || 
                    /\/company\/\d+\/edit/.test(window.location.pathname) ||
                    /\/company\/\d+\/subsidiaries/.test(window.location.pathname) ||
                    /\/company\/\d+\/moderate/.test(window.location.pathname)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Companies
                </NavLink>
              </li>
            ) : null}
            {userRoles.includes("administrator") ? (
              <li className="nav-item">
                <NavLink
                  to="/companies-pending-review"
                  className={({ isActive }) =>
                    isActive || /\/company\/[^/]+\/review/.test(window.location.pathname)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Companies Pending Review
                </NavLink>
              </li>
            ) : null}
            {userRoles.includes("administrator") ? (
              <li className="nav-item">
                <NavLink to="/all-subsidiaries" className={({ isActive }) =>
                    isActive
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  All Subsidiaries
                </NavLink>
              </li>
            ) : null}
            {userRoles.includes("researcher") || userRoles.includes("administrator") ? (
              <li className="nav-item">
                <NavLink to="/company/add" className={({ isActive }) =>
                    isActive
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  Add Company
                </NavLink>
              </li>
            ) : null}
            {userRoles.includes("administrator") ? (
              <li className="nav-item">
                <NavLink to="/subs-no-parents" className={({ isActive }) =>
                    isActive
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  Subs with No Parents
                </NavLink>
              </li>
            ) : null}
            {userRoles.includes("administrator") ? (
              <li className="nav-item">
                <NavLink to="/researcher-activity-report" className={({ isActive }) =>
                    isActive
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  Researcher Activity Report
                </NavLink>
              </li>
            ) : null}
            {userRoles.includes("administrator") ? (
              <li className="nav-item">
                <NavLink to="/latest-companies" className={({ isActive }) =>
                    isActive
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  Latest Companies Report
                </NavLink>
              </li>
            ) : null}
          </div>
          {user && (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <button onClick={this.handleLogout} className="nav-link btn btn-link text-white">
                  Logout
                </button>
              </li>
            </div>
          )}
        </nav>

        <div className="container-fluid mt-3">
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/" element={<ProtectedRoute allowedRoles={['administrator', 'researcher']}><CompaniesList key="assigned-companies"/></ProtectedRoute>} />
            <Route path="/companies" element={<ProtectedRoute allowedRoles={['administrator']}><CompaniesList key="all-companies"/></ProtectedRoute>} />
            {/* <ProtectedRoute exact path="/add" component={AddCompany} /> */}
            {/* <ProtectedRoute exact path="/add/:ParentNID" component={AddCompany} /> */}
            <Route path="/company/:id/edit" element={<ProtectedRoute allowedRoles={['administrator', 'researcher']}><CompanyEdit /></ProtectedRoute>} />
            <Route path="/company/:id/moderate" element={<ProtectedRoute allowedRoles={['administrator', 'researcher']}><ModerateCompany /></ProtectedRoute>} />
            <Route path="/company/:id/assign-researcher" element={<ProtectedRoute allowedRoles={['administrator']}><AssignResearcher /></ProtectedRoute>} />
            <Route path="/company/:id/review" element={<ProtectedRoute allowedRoles={['administrator', 'researcher']}><ReviewChanges /></ProtectedRoute>} />
            <Route path="/subsidiary/:id/edit" element={<ProtectedRoute allowedRoles={['administrator', 'researcher']}><SubsidiaryEdit /></ProtectedRoute>} />
            <Route path="/company/:id/subsidiaries" element={<ProtectedRoute allowedRoles={['administrator', 'researcher']}><SubsidiariesList key="company-subs"/></ProtectedRoute>} />
            <Route path="/company/add" element={<ProtectedRoute allowedRoles={['administrator', 'researcher']}><CompanyEdit /></ProtectedRoute>} />
            <Route path="/companies-pending-review" element={<ProtectedRoute allowedRoles={['administrator']}><CompaniesPendingReview /></ProtectedRoute>} />
            <Route path="/all-subsidiaries" element={<ProtectedRoute allowedRoles={['administrator']}><SubsidiariesList specialSubList="all-subs" key="all-subs"/></ProtectedRoute>} />
            <Route path="/researcher-activity-report" element={<ProtectedRoute allowedRoles={['administrator']}><ResearcherActivityReport/></ProtectedRoute>} />
            <Route path="/latest-companies" element={<ProtectedRoute allowedRoles={['administrator']}><LatestCompanies/></ProtectedRoute>} />
            <Route path="/subs-no-parents" element={<ProtectedRoute allowedRoles={['administrator']}><SubsidiariesList specialSubList="subs-no-parents" key="subs-no-parents"/></ProtectedRoute>} />
            {/* <ProtectedRoute exact path="/companies/:NID/subsidiaries" component={CompaniesList} /> */}
            {/* <ProtectedRoute exact path="/companies-pending-review" component={CompaniesPendingReview} /> */}
            {/* <ProtectedRoute exact path ="/companies-pending-review/:NID/review-changes" component={ReviewChanges} /> */}
            {/* <ProtectedRoute exact path="/drafts" component={DraftCompanies} /> */}
            {/* <ProtectedRoute exact path="/drafts/:NID/subsidiaries" component={DraftCompanies} /> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    );
  }
  // The Switch controllers above need to use a different component type otherwise Link won't work.
  // Need to duplicate CompaniesList to SubsidiariesList or something similar.
}

export default App;
